import React, { useEffect, useState } from "react";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { Button } from '@mui/material';


// <!-- Google Fonts -->
import '../../css/material-icon.css';

// <!-- Bootstrap Core Css -->
import '../../plugins/bootstrap/css/bootstrap.css';

// <!-- Waves Effect Css -->
import '../../plugins/node-waves/waves.css';

// <!-- Animation Css -->
import '../../plugins/animate-css/animate.css';

// <!-- Bootstrap Select Css -->
import '../../plugins/bootstrap-select/css/bootstrap-select.css';


// <!-- JQuery DataTable Css -->
import '../../plugins/jquery-datatable/skin/bootstrap/css/dataTables.bootstrap.css';
// <!-- Bootstrap Material Datetime Picker Css -->
import '../../plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';

// <!-- Bootstrap DatePicker Css -->
import '../../plugins/bootstrap-datepicker/css/bootstrap-datepicker.css';

// <!-- Wait Me Css -->
import '../../plugins/waitme/waitMe.css';

// <!-- Morris Chart Css-->
import '../../plugins/morrisjs/morris.css';

// <!-- Custom Css -->
import '../../css/style.css';
import '../../css/custom.css';
import '../../css/materialize.css';




// <!-- Style -->
// <!--<link rel="stylesheet" href="css/style.css">-->

// <!-- AdminBSB Themes. You can choose a theme from css/themes instead of get all themes -->
import '../../css/themes/all-themes.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import config from '../../redux/config';
import { logoutApi, mimicAPI } from "../../redux/ApiActionCreator";
const Topbar = () => {
    const username = useSelector((state) => state?.apiReducer?.loginData?.Data?.username)
    const emailId = useSelector((state) => state?.apiReducer?.loginData?.Data?.email)
    const profilePic = useSelector((state) => state?.apiReducer?.loginData?.Data?.UserProfile)
    const userRoles = useSelector((state) => state?.apiReducer?.loginData?.Data?.userRole[0]);
    const mimicFlag = useSelector((state) => state?.apiReducer?.mimicFlag)
    const mimicData = useSelector((state) => state?.apiReducer?.mimicData)
    console.log(profilePic)
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const personOpen = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseDrop = () => {
        setAnchorEl(null);

    };

    const onClicklogout = () => {
        if (userRoles == 'AgentAdmin' && mimicFlag == true) {

            dispatch(mimicAPI(mimicData?.Data?.accessToken, mimicData?.Data?.email))
                .then((response) => {

                    navigate('/')

                })
                .catch((error) => {

                    if (error.response && error.response.status === 401) {

                        dispatch(logoutApi())
                        navigate('/')

                    }

                });
        } else {

            dispatch(logoutApi())
            navigate('/')
        }

    }

    return (
        <nav class="navbar common">
            <div class="container-fluid">
                <div class="navbar-header">
                    <a href="javascript:void(0);" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse" aria-expanded="false"></a>
                    <a href="javascript:void(0);" class="bars"></a>
                    <a class="navbar-brand" ><img style={{ height: 65 }} src={require('../../images/logo.png')} /></a>
                </div>
                <div class="collapse navbar-collapse" id="navbar-collapse">
                    <ul class="nav navbar-nav navbar-right">
                        <li>
                            <div class="user-info">
                                <div class="image">
                                    {
                                        (profilePic == null || profilePic == "") ?
                                            (<img class="user_image" src={require("../../images/user.png")} width="48" height="48" alt="User" />) :
                                            (<img class="user_image" src={`${config.baseURL}${profilePic}`} width="48" height="48" alt="User" />)
                                    }

                                    <Button

                                        onClick={handleClick}
                                        className="drop_menu"
                                    >
                                        <i class="material-icons profile_icon">keyboard_arrow_down</i>
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        className="drop_menu"
                                        open={personOpen}
                                        onClose={handleCloseDrop}
                                        disableScrollLock={true}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <p>{username}</p>
                                        <p>{emailId}</p>
                                        {/* <MenuItem role="separator" class="divider"></MenuItem> */}


                                        {/* <MenuItem onClick={() => navigate('/EditProfile')}><i class="material-icons">person</i>{t('Edit Profile')}</MenuItem> */}
                                        <MenuItem role="separator" class="divider"></MenuItem>


                                        <MenuItem onClick={() => onClicklogout()}><i class="material-icons">input</i>{t('signOut')}</MenuItem>
                                    </Menu>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Topbar