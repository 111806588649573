import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { scroller } from 'react-scroll';
import folder from '../../images/folder.svg';
import closeModal from '../../images/close.svg';
import deleteIcon from '../../images/delete.svg';
import axios from 'axios';
import config from '../../redux/config';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Button, Tooltip } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';


// <!-- Google Fonts -->
import '../../css/material-icon.css';

// <!-- Bootstrap Core Css -->
import '../../plugins/bootstrap/css/bootstrap.css';

// <!-- Waves Effect Css -->
import '../../plugins/node-waves/waves.css';

// <!-- Animation Css -->
import '../../plugins/animate-css/animate.css';

// <!-- Bootstrap Select Css -->
import '../../plugins/bootstrap-select/css/bootstrap-select.css';


// <!-- JQuery DataTable Css -->
import '../../plugins/jquery-datatable/skin/bootstrap/css/dataTables.bootstrap.css';
// <!-- Bootstrap Material Datetime Picker Css -->
import '../../plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';

// <!-- Bootstrap DatePicker Css -->
import '../../plugins/bootstrap-datepicker/css/bootstrap-datepicker.css';

// <!-- Wait Me Css -->
import '../../plugins/waitme/waitMe.css';

// <!-- Morris Chart Css-->
import '../../plugins/morrisjs/morris.css';

// <!-- Custom Css -->
import '../../css/style.css';
import '../../css/custom.css';
import '../../css/materialize.css';
import '../../css/uicons-bold-rounded.css';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



// <!-- Style -->
// <!--<link rel="stylesheet" href="css/style.css">-->

// <!-- AdminBSB Themes. You can choose a theme from css/themes instead of get all themes -->
import '../../css/themes/all-themes.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Topbar from '../../component/Topbar';
import { useDispatch, useSelector } from 'react-redux';
import { addAgentCreditListApi, agentApi, agentCreditListApi, agentUploadDocumentListApi, cityApi, companyModeApi, countryApi, createAgentApi, deleteAgentCreditListApi, editAgentApi, getAgentDetailsApi, logoutApi, maritialStatusApi, stateApi } from '../../redux/ApiActionCreator';
import Sidebar from '../../component/Sidebar';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function AgentDetail() {
    const field1Ref = useRef();
    const field2Ref = useRef();
    const field3Ref = useRef();
    const field4Ref = useRef();
    const field5Ref = useRef();
    const field6Ref = useRef();
    const field7Ref = useRef();


    const { t } = useTranslation();
    const dispatch = useDispatch()
    const theme = useTheme();
    const navigate = useNavigate()
    const [personName, setPersonName] = useState([]);
    const [open, setOpen] = useState(false);
    const [companyAccordingList, setCompanyAccordingList] = useState(true)
    const [primaryAccordingList, setPrimaryAccordingList] = useState(true)
    const [value, setValue] = React.useState('one');
    const [companyName, setCompanyName] = useState('')
    const [companyContactNo, setCompanyContactNo] = useState('')

    const [designation, setDesignation] = useState('')
    const [licenseNo, setLicenseNo] = useState('')
    const [companyReg, setCompanyReg] = useState('')
    const [city, setCity] = useState('')
    const [cityId, setCityId] = useState('')
    const [stateName, setStateName] = useState('')
    const [country, setCountry] = useState('')
    const [zipCode, setzipCode] = useState('')
    const [companyAddress, setCompanyAddress] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const [age, setAge] = useState('')
    const [date, setDate] = useState('')
    const [idNumber, setIdNumber] = useState('')
    const [password, setPassword] = useState('')
    const [passportExpireDate, setPassportExpireDate] = useState('')
    const [nationality, setNationality] = useState('')
    const [countryOfResidence, setCountryOfResidence] = useState('')
    const [maritalStatus, setMaritalStatus] = useState('')
    const [address, setAddress] = useState('')
    const [credit, setCredit] = useState('')
    const [description, setDescription] = useState('')
    const [loading, setLoading] = useState(false)
    const [selectedDocumentId, setSelectedDocumentId] = useState('')
    const [passwordShow, setpasswordShow] = useState(false)
    const [creditSelectedId, setcreditSelectedId] = useState('')
    const [creditPopOpen, setcreditPopOpen] = useState(false)
    const companyMode = useSelector((state) => state?.apiReducer?.companyMode)
    const [companyNameError, setcompanyNameError] = useState('')
    const [companyContactNoError, setCompanyContactNoError] = useState('')
    const [licenseAuthority, setLicenseAuthority] = useState("")
    const [expiryDate, setExpiryDate] = useState("")

    const [selectedOption, setSelectedOption] = useState('qr'); // Default to 'qr'
    const [qrFileName, setQrFileName] = useState('');
    const [qrByteCode, setQrByteCode] = useState('');
    const [qrUploadError, setQrUploadError] = useState('')
    const [websiteLink, setWebsiteLink] = useState('');
    const [websiteLinkError, setWebsiteLinkError] = useState('');
    const [profilePictureFileName, setProfilePictureFileName] = useState('')
    const [profilePictureByteCode, setProfilePictureByteCode] = useState('')


    const [licenseNoError, setlicenseNoError] = useState('')
    const [firstNameError, setFirstNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [contactError, setcontactError] = useState('')
    const [passwordError, setpasswordError] = useState('')
    const [documentError, setDocumentError] = useState('')
    const [creditError, setcreditError] = useState('')
    const [descriptionError, setDescriptionError] = useState('')
    const [companyLogoUploadError, setCompanyLogoUploadError] = useState('')

    const [inputTypeBirthdate, setInputTypeBirthdate] = useState('text');
    const [inputTypeExpiredate, setInputTypeExpiredate] = useState('text');
    const [imageByteCode, setImageByteCode] = useState('');
    const [imageName, setImageName] = useState('');
    const [discription, setDiscription] = useState('')
    const today = new Date().toISOString().split('T')[0];
    const countryData = useSelector((state) => state?.apiReducer?.countryData)
    const stateData = useSelector((state) => state?.apiReducer?.stateData)
    const cityData = useSelector((state) => state?.apiReducer?.cityData)
    const token = useSelector((state) => state?.apiReducer?.loginData?.Data?.accessToken)
    const agentUploadDocumentsList = useSelector((state) => state?.apiReducer?.agentUploadDocumentsListData)
    const agentCreditList = useSelector((state) => state?.apiReducer?.agentCreditListData)
    const createAgentData = useSelector((state) => state?.apiReducer?.createAgentData)
    const maritialStatusData = useSelector((state) => state?.apiReducer?.maritialStatusData)
    const getAgentDetails = useSelector((state) => state?.apiReducer?.getAgentDetailsData)
    const getAgentOffData = useSelector((state) => state?.apiReducer?.agentOffData)
    const [agentUploadDocumentsListPage, setAgentUploadDocumentsListPage] = useState(1)
    const [agentCreditListPage, setAgentCreditListPage] = useState(1)
    const [inputExpiredate, setInputExpiredate] = useState('text');
    const [faxNo, setFaxNo] = useState("")

    const maxLength = 7;
    const fieldDefaultRefTop = useRef();



    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Allow digits, decimal point, and backspace
        if (!/^\d$/.test(keyValue) && keyCode !== 8) {
            event.preventDefault();
        }

        // Prevent typing a minus sign
        if (keyCode === 45) {
            event.preventDefault();
        }
    };
    const handleKeyPressPhno = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Allow digits, plus sign, and backspace
        if (!/^\d$/.test(keyValue) && keyValue !== "+") {
            event.preventDefault();
        }

        // Prevent typing a minus sign
        if (keyCode === 45) {
            event.preventDefault();
        }
    };



    const pageHandleChange = (event, value) => {
        setAgentUploadDocumentsListPage(value);
    };
    const pageHandleChangeAgentCreditList = (event, value) => {
        setAgentCreditListPage(value);
    };

    const handleFocusDate = (e) => {
        setInputTypeBirthdate('date');
        e.target.blur()
    };

    const handleFocusExpireDate = (e) => {
        setInputTypeExpiredate('date');
        e.target.blur()
    };


    useEffect(() => {
        scroller.scrollTo(fieldDefaultRefTop.current.id, {
            smooth: false,
            offset: -100,
        });
    }, [])

    //COUNTRY API CALL
    useEffect(() => {
        dispatch(countryApi(token))
            .then((response) => {

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {

                    dispatch(logoutApi())
                    navigate('/')

                }

            });
        dispatch(maritialStatusApi(token))

    }, [])

    //AGENT DOCUMENT LIST API CALL
    useEffect(() => {
        const params = {
            pagenumber: agentUploadDocumentsListPage,
            UserId: getAgentOffData?.agentType == 'create' ? createAgentData?.AgentId : getAgentDetails?.UserId
        }
        dispatch(agentUploadDocumentListApi(token, params))
    }, [agentUploadDocumentsListPage, value])

    //AGENT CREDIT LIST API CALL
    useEffect(() => {

        const params = {
            pagenumber: agentCreditListPage,
            agentId: getAgentOffData?.agentType == 'create' ? createAgentData?.AgentId : getAgentDetails?.UserId
        }
        dispatch(agentCreditListApi(token, params))
    }, [agentCreditListPage, value])


    //STATE API CALL
    useEffect(() => {
        dispatch(stateApi(token, country));

    }, [country]);

    //CITY API CALL
    useEffect(() => {

        dispatch(cityApi(token, stateName));

    }, [stateName]);

    //GET AGENT DETAIL API CALL
    useEffect(() => {
        if (getAgentOffData?.agentType != 'create' || !companyMode?.mode == 0) {

            dispatch(getAgentDetailsApi(token, getAgentDetails?.ComanyId));
        }

    }, [])

    //DATE FORMATE
    function convertDateFormat(inputDate) {
        // Split the date string into parts
        const parts = inputDate.split('-');

        // Rearrange the parts to dd-mm-yyyy format
        const outputDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

        return outputDate;
    }

    useEffect(() => {
        if (getAgentOffData?.agentType != 'create' || !companyMode?.mode == 0) {

            setCompanyName(getAgentDetails?.CompanyName == null ? '' : getAgentDetails?.CompanyName)
            setCompanyContactNo(getAgentDetails?.CompanyContactNo == null ? '' : getAgentDetails?.CompanyContactNo)
            setDesignation(getAgentDetails?.CompanyDesignation == null ? '' : getAgentDetails?.CompanyDesignation)
            setLicenseNo(getAgentDetails?.CompanyLicNo == null ? '' : getAgentDetails?.CompanyLicNo)
            setFaxNo(getAgentDetails?.FaxNo == null ? "" : getAgentDetails?.FaxNo)
            setCompanyReg(getAgentDetails?.CompanyRegNo == null ? '' : getAgentDetails?.CompanyRegNo)
            setLicenseAuthority(getAgentDetails?.LicenseAuthority == null ? '' : getAgentDetails?.LicenseAuthority)
            setExpiryDate(getAgentDetails?.LicenseExpiryDateStr == null ? null : convertDateFormat(getAgentDetails?.LicenseExpiryDateStr))
            setCountry(getAgentDetails?.CompanyCountryId == null ? '' : getAgentDetails?.CompanyCountryId)
            setStateName(getAgentDetails?.CompanyStateId == null ? '' : getAgentDetails?.CompanyStateId)
            setCityId(getAgentDetails?.CompanyCityId == null ? '' : getAgentDetails?.CompanyCityId)
            setzipCode(getAgentDetails?.CompanyZipcode == null ? '' : getAgentDetails?.CompanyZipcode)
            setCompanyAddress(getAgentDetails?.CompanyAddress == null ? '' : getAgentDetails?.CompanyAddress)
            setFirstName(getAgentDetails?.FirstName == null ? '' : getAgentDetails?.FirstName)
            setLastName(getAgentDetails?.LastName == null ? '' : getAgentDetails?.LastName)
            setEmail(getAgentDetails?.Email == null ? '' : getAgentDetails?.Email)
            setContact(getAgentDetails?.Phone == null ? '' : getAgentDetails?.Phone)
            setAge(getAgentDetails?.Age == null ? '' : getAgentDetails?.Age)
            setDate(getAgentDetails?.DobStr == null ? null : convertDateFormat(getAgentDetails?.DobStr))
            setIdNumber(getAgentDetails?.Idnumber == null ? '' : getAgentDetails?.Idnumber)
            setPassword(getAgentDetails?.Password == null ? '' : getAgentDetails?.Password)
            setPassportExpireDate(getAgentDetails?.PassportExpiryDateStr == null ? null : convertDateFormat(getAgentDetails?.PassportExpiryDateStr))
            setNationality(getAgentDetails?.Nationality == null ? '' : getAgentDetails?.Nationality)
            setCountryOfResidence(getAgentDetails?.CountryOfResidence == null ? '' : getAgentDetails?.CountryOfResidence)
            setMaritalStatus(getAgentDetails?.MaritalStatus == null ? '' : getAgentDetails?.MaritalStatus)
            setAddress(getAgentDetails?.Address == null ? '' : getAgentDetails?.Address)
            setImageName(getAgentDetails?.OldFileName)
            setDiscription(getAgentDetails?.CompanyDescription)
            setSelectedOption(getAgentDetails?.IsQRcode == true ? "qr" : "website")
            setQrFileName(getAgentDetails?.QRCodeImageFileNameWithExtension)
            setWebsiteLink(getAgentDetails?.QRCodeUrl)
            setProfilePictureFileName(getAgentDetails?.UserImageFileNameWithExtension)
        }

    }, [])



    const handleCountryChange = (e) => {

        const selectedCountry = e.target.value;

        setCountry(selectedCountry);
        // Reset state and city when country changes
        setStateName('');
        setCityId('');
    };

    const handleStateChange = (e) => {
        const selectedState = e.target.value;
        setStateName(selectedState);
        // Reset city when state changes
        setCityId('');
    };

    const handleCityChange = (e) => {
        const selectedCityName = e?.target?.value;

        setCityId(selectedCityName);
    };



    //ADD CREDIT API CALL
    const onClickCredit = () => {
        let validate = false
        if (credit == '') {
            validate = true
            setcreditError(t('pleaseEnterAddNoredits'));
        } else {
            setcreditError('');
        }

        if (description.trim().length == 0) {
            validate = true
            setDescriptionError(t('pleaseEnterDecription'));
        } else {
            setDescriptionError('');
        }

        if (validate == false) {
            setLoading(true)
            const params = {
                AgentId: getAgentOffData?.agentType == 'create' ? createAgentData?.AgentId : getAgentDetails?.UserId,
                Credits: credit,
                Description: description,
            }

            dispatch(addAgentCreditListApi(token, params))
                .then((response) => {
                    setLoading(false)
                    toast.success("Credits Added Successfully", {
                        position: 'top-right',
                        autoClose: 1000, // Adjust the duration as needed
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    const params = {
                        pagenumber: agentCreditListPage,
                        agentId: getAgentOffData?.agentType == 'create' ? createAgentData?.AgentId : getAgentDetails?.UserId,
                    }
                    dispatch(agentCreditListApi(token, params))
                    setCredit('')
                    setDescription('')


                })
                .catch((error) => {
                    setLoading(false)
                });
        }
    }



    const validateDec = (value) => {
        if (value.trim().length === 0) {
            setDescriptionError(t('pleaseEnterDecription'));
        } else {
            setDescriptionError('');
        }
    }


    const onClickDocumentNext = async () => {
        const params = {
            pagenumber: 1,
            agentId: getAgentOffData?.agentType == 'create' ? createAgentData?.AgentId : getAgentDetails?.UserId
        }
        dispatch(agentCreditListApi(token, params))
            .then(() => {
                setValue('three')

            })
            .catch(error => {

            })

    }

    //AGENT DOCUMENT UPLOAD
    const handleFileChange = async (e) => {
        setLoading(true)
        const file = e.target.files[0];
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/plain'];

        if (!allowedTypes.includes(file?.type)) {
            setLoading(false)

            alert('Please upload a JPG, JPEG, PNG, DOC, PDF,TXT or XLSX file.')

            e.target.value = null;
            // Trigger an additional event to force recognition of the change

            e.target.dispatchEvent(new Event('input'));

            return; // Exit the function if file type is not allowed

        }
        try {
            // Create a FormData object
            const formData = new FormData();

            // Add form fields to the FormData object
            formData.append('file', file);
            formData.append('UserId', getAgentOffData?.agentType == 'create' ? createAgentData?.AgentId : getAgentDetails?.UserId);

            const response = await axios.post(
                `${config.baseURL}/User/UploadFile`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Set the content type to FormData
                        Authorization: `Bearer ${token}`,
                        // Add any other headers if needed
                    },
                }
            );

            // Handle the response here (e.g., update state or perform other actions)
            if (response.data.Status == 'OK') {
                e.target.value = null;

                e.target.dispatchEvent(new Event('input'));

                const params = {
                    pagenumber: 1,
                    UserId: getAgentOffData?.agentType == 'create' ? createAgentData?.AgentId : getAgentDetails?.UserId
                }
                dispatch(agentUploadDocumentListApi(token, params))
                    .then((response) => {
                        setLoading(false)
                        toast.success("File Uploaded Successfully", {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });

                    })
            } else {

            }

        } catch (error) {


        }

    };



    const validateCompanyName = (value) => {
        if (value.length === 0) {
            setcompanyNameError(t('companyNamerequired'));
        } else {
            setcompanyNameError('');
        }
    };



    const validateLicenseNo = (value) => {
        if (value.length === 0) {
            setlicenseNoError(t('licenseisrequired'));
        } else {
            setlicenseNoError('');
        }
    };




    const validateFirstName = (value) => {
        if (value.length === 0) {
            setFirstNameError(t('firstNameisrequired'));
        } else {
            setFirstNameError('');
        }
    };

    const validateWebsiteLink = (value) => {
        if (selectedOption == "website") {
            if (value.length === 0) {
                setWebsiteLinkError("Company Website Link is Required");
            } else {
                setWebsiteLinkError('');
            }
        }
    };



    const validateEmail = (value) => {
        if (value.length === 0) {
            setEmailError(t('emailisrequired'));
        } else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))) {

            setEmailError('Enter valid email');

        }
        else {
            setEmailError('');
        }
    };

    const validateContact = (value) => {
        if (value.length <= 4) {
            setcontactError(t('contactNoisrequired'));
        } else {
            setcontactError('');
        }
    };





    const validatePassword = (value) => {
        if (value.length === 0) {
            setpasswordError(t('passwordRequired'));
        }
        else if (value.length < 8) {
            setpasswordError('password must be at least 8 characters');
        } else {
            setpasswordError('');
        }
    };



    const validateAndFormatDate = (dateString) => {
        // Check if the date is in dd-mm-yyyy format
        const isDDMMYYYYFormat = /^\d{2}-\d{2}-\d{4}$/.test(dateString);

        // If it's in dd-mm-yyyy format, convert to yyyy-mm-dd
        if (isDDMMYYYYFormat) {
            const [day, month, year] = dateString.split('-');
            const convertedDate = `${year}-${month}-${day}`;
            return convertedDate;
        }

        // If it's not in dd-mm-yyyy format, return the original date
        return dateString;
    };

    // Example usage



    //ONCLICK NEXT BUTTON AGENT CREATE AND UPDATE 
    const agentDetailNextApi = () => {

        let validate = false
        if (companyName == '') {
            validate = true
            setcompanyNameError(t('companyNamerequired'));
        } else {
            setcompanyNameError('');
        }

        if (licenseNo == '') {
            validate = true
            setlicenseNoError(t('licenseisrequired'));
        } else {
            setlicenseNoError('');
        }

        if (firstName == '') {
            validate = true
            setFirstNameError(t('firstNameisrequired'));
        } else {
            setFirstNameError('');
        }


        if (email == '') {
            validate = true
            setEmailError(t('emailisrequired'));
        } else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))) {
            validate = true
            setEmailError('Enter valid email');

        }
        else {
            setEmailError('');
        }

        if (contact.length <= 4) {
            validate = true
            setcontactError(t('contactNoisrequired'));
        } else {
            setcontactError('');
        }
        if (password == '') {
            validate = true
            setpasswordError(t('passwordError'));
        } else if (password.length < 8) {
            setpasswordError('password must be at least 8 characters');
        } else {
            setpasswordError('');
        }

        if (imageName == '') {
            validate = true
            setCompanyLogoUploadError('Please Upload Company Logo');
        } else {
            setCompanyLogoUploadError('');
        }

        if (selectedOption == "qr") {
            if (qrFileName == '' || qrFileName == null) {
                validate = true
                setQrUploadError('Please Upload Qr Code For Company Website');
            } else {
                setQrUploadError('');
            }
        }
        else if (selectedOption == "website") {
            if (websiteLink == '' || websiteLink == null) {
                validate = true
                setWebsiteLinkError('Company Website Link is required');
            } else {
                setWebsiteLinkError('');
            }
        }
        if (validate == true) {
            // Find the topmost mandatory field that is not filled
            let topmostFieldRef;
            if (!field1Ref.current.value) {
                topmostFieldRef = field1Ref;
            }
            else if (!licenseNo) {
                topmostFieldRef = field1Ref;
            }
            else if (imageName == "") {
                topmostFieldRef = field5Ref;
            }
            else if (selectedOption == "qr") {
                if (qrFileName == "")
                    topmostFieldRef = field7Ref
            }
            else if (selectedOption == "website") {
                if (websiteLink == "")
                    topmostFieldRef = field7Ref
            }

            else if (!field2Ref.current.value) {
                topmostFieldRef = field2Ref;
            }
            else if (!field3Ref.current.value || !(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(field3Ref.current.value))) {
                topmostFieldRef = field3Ref;
            }

            else if (!field6Ref.current.value) {
                topmostFieldRef = field6Ref;
            }

            // Add more conditions for other mandatory fields as needed

            // Scroll to the topmost mandatory field
            if (topmostFieldRef && topmostFieldRef.current) {
                scroller.scrollTo(topmostFieldRef.current.id, {
                    smooth: true,
                    offset: -100, // Adjust as needed
                });
            }

            else if (imageName == "") {
                scroller.scrollTo(topmostFieldRef.current.id, {
                    smooth: true,
                    offset: -100, // Adjust as needed
                });
            }

            else if (contact.length <= 4) {
                scroller.scrollTo(field4Ref.current.id, {
                    smooth: true,
                    offset: -100, // Adjust as needed
                });
            }


            return;
        }
        if (validate == false) {

            setLoading(true)

            if (getAgentOffData?.agentType == 'create' && companyMode?.mode == 0) {

                const params = {

                    CompanyName: companyName == "" ? null : companyName,
                    CompanyDesignation: designation == "" ? null : designation,
                    CompanyLicNo: licenseNo == "" ? null : licenseNo,
                    CompanyContactNo: companyContactNo === "" ? null : (companyContactNo.startsWith("+") ? companyContactNo : `+${companyContactNo}`),
                    CompanyRegNo: companyReg == "" ? null : companyReg,
                    LicenseAuthority: licenseAuthority == "" ? null : licenseAuthority,
                    FaxNo: faxNo == "" ? null : faxNo,
                    LicenseExpiryDate: expiryDate == "" ? null : validateAndFormatDate(expiryDate),
                    CompanyCountryId: country == "" ? null : country,
                    CompanyStateId: stateName == "" ? null : stateName,
                    CompanyCityId: cityId == "" ? null : cityId,
                    CompanyZipcode: zipCode == "" ? null : zipCode,
                    CompanyAddress: companyAddress == "" ? null : companyAddress,
                    FirstName: firstName == "" ? null : firstName,
                    LastName: lastName == "" ? null : lastName,
                    Email: email == "" ? null : email,
                    Phone: contact === "" ? null : (contact.startsWith("+") ? contact : `+${contact}`),
                    Age: age == "" ? null : age,
                    Dob: date == "" ? null : date,
                    Idnumber: idNumber == "" ? null : idNumber,
                    Password: password == "" ? null : password,
                    PassportExpiryDate: passportExpireDate == "" ? null : passportExpireDate,
                    Nationality: nationality == "" ? null : nationality,
                    CountryOfResidence: countryOfResidence == "" ? null : countryOfResidence,
                    MaritalStatus: maritalStatus == "" ? null : maritalStatus,
                    Address: address == "" ? null : address,
                    CompanyLogoBase64: imageByteCode == "" ? null : imageByteCode,
                    CompanyLogoFileNameWithExtension: imageName == "" ? null : imageName,
                    CompanyDescription: discription == "" ? null : discription,
                    QRCodeImageBase64: selectedOption == "qr" ? (qrByteCode == "" ? null : qrByteCode) : null,
                    QRCodeImageFileNameWithExtension: selectedOption == "qr" ? qrFileName : null,
                    QRCodeUrl: selectedOption == "website" ? websiteLink : null,
                    UserImageFileNameWithExtension: (profilePictureFileName == null || profilePictureFileName == "") ? null : profilePictureFileName,
                    UserImageBase64: profilePictureByteCode == "" ? null : profilePictureByteCode,
                    IsQRcode: selectedOption == "qr" ? true : false
                }
                dispatch(createAgentApi(token, params))
                    .then((response) => {
                        dispatch(companyModeApi(1))
                        const params = {
                            pagenumber: null
                        }
                        dispatch(agentApi(token, params))
                            .then((response) => {


                                const params = {
                                    pagenumber: 1,
                                    UserId: getAgentOffData?.agentType == 'create' ? createAgentData?.AgentId : getAgentDetails?.UserId
                                }
                                dispatch(agentUploadDocumentListApi(token, params))
                                    .then((response) => {
                                        const params = {
                                            pagenumber: 1,
                                            agentId: getAgentOffData?.agentType == 'create' ? createAgentData?.AgentId : getAgentDetails?.UserId
                                        }
                                        dispatch(agentCreditListApi(token, params))
                                        toast.success("Record Saved Successfully", {
                                            position: 'top-right',
                                            autoClose: 1000, // Adjust the duration as needed
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                        });
                                        setTimeout(() => {
                                            setLoading(false)
                                            setValue('two');

                                        }, 1000);

                                    })
                                    .catch((error) => {
                                        setLoading(false)

                                    });

                            })
                            .catch((error) => {

                            });

                    })
                    .catch((error) => {
                        setLoading(false)
                        toast.error(error, {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });

                    });
            } else {

                const params = {
                    ComanyId: getAgentOffData?.agentType == 'create' ? createAgentData?.CompanyId : getAgentDetails?.ComanyId,
                    CompanyName: companyName == "" ? null : companyName,
                    CompanyContactNo: companyContactNo === "" ? null : (companyContactNo.startsWith("+") ? companyContactNo : `+${companyContactNo}`),
                    CompanyDesignation: designation == "" ? null : designation,
                    CompanyLicNo: licenseNo == "" ? null : licenseNo,
                    CompanyRegNo: companyReg == "" ? null : companyReg,
                    LicenseAuthority: licenseAuthority == "" ? null : licenseAuthority,
                    FaxNo: faxNo == "" ? null : faxNo,
                    LicenseExpiryDate: expiryDate == "" ? null : validateAndFormatDate(expiryDate),
                    CompanyCountryId: country == "" ? null : country,
                    CompanyStateId: stateName == "" ? null : stateName,
                    CompanyCityId: cityId == "" ? null : cityId,
                    CompanyZipcode: zipCode == "" ? null : zipCode,
                    CompanyAddress: companyAddress == "" ? null : companyAddress,
                    FirstName: firstName == "" ? null : firstName,
                    LastName: lastName == "" ? null : lastName,
                    Phone: contact === "" ? null : (contact.startsWith("+") ? contact : `+${contact}`),
                    Age: age == "" ? null : age,
                    Dob: date == "" ? null : validateAndFormatDate(date),
                    Idnumber: idNumber == "" ? null : idNumber,
                    PassportExpiryDate: passportExpireDate == "" ? null : validateAndFormatDate(passportExpireDate),
                    Nationality: nationality == "" ? null : nationality,
                    CountryOfResidence: countryOfResidence == "" ? null : countryOfResidence,
                    MaritalStatus: maritalStatus == "" ? null : maritalStatus,
                    Address: address == "" ? null : address,
                    CompanyLogoBase64: imageByteCode == "" ? null : imageByteCode,
                    CompanyLogoFileNameWithExtension: imageName == "" ? null : imageName,
                    CompanyDescription: discription == "" ? null : discription,
                    QRCodeImageBase64: selectedOption == "qr" ? (qrByteCode == "" ? null : qrByteCode) : null,
                    QRCodeImageFileNameWithExtension: selectedOption == "qr" ? qrFileName : null,
                    QRCodeUrl: selectedOption == "website" ? websiteLink : null,
                    UserImageFileNameWithExtension: (profilePictureFileName == null || profilePictureFileName == "") ? null : profilePictureFileName,
                    UserImageBase64: profilePictureByteCode == "" ? null : profilePictureByteCode,
                    IsQRcode: selectedOption == "qr" ? true : false
                }

                dispatch(editAgentApi(token, params))
                    .then((response) => {
                        const params = {
                            pagenumber: null
                        }
                        dispatch(agentApi(token, params))
                            .then((response) => {
                                const params = {
                                    pagenumber: 1,
                                    UserId: getAgentOffData?.agentType == 'create' ? createAgentData?.AgentId : getAgentDetails?.UserId
                                }
                                dispatch(agentUploadDocumentListApi(token, params))
                                    .then((response) => {

                                        toast.success("Record Saved Successfully", {
                                            position: 'top-right',
                                            autoClose: 1000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                        });
                                        setTimeout(() => {
                                            setLoading(false)
                                            setValue('two');

                                        }, 1000);
                                    })
                                    .catch((error) => {
                                        setLoading(false)

                                    });
                            })

                    })
                    .catch((error) => {


                    });
            }


        }


    };

    //ONCLICK SAVE BUTTON AGENT CREATE AND UPDATE
    const agentDetailApi = () => {

        let validate = false
        if (companyName == '') {
            validate = true
            setcompanyNameError(t('companyNamerequired'));
        } else {
            setcompanyNameError('');
        }

        if (licenseNo == '') {
            validate = true
            setlicenseNoError(t('licenseisrequired'));
        } else {
            setlicenseNoError('');
        }

        if (firstName == '') {

            validate = true
            setFirstNameError(t('firstNameisrequired'));
        } else {
            setFirstNameError('');
        }


        if (email == '') {
            validate = true
            setEmailError(t('emailisrequired'));
        } else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))) {

            validate = true
            setEmailError('Enter valid email');

        }
        else {
            setEmailError('');
        }

        if (contact.length <= 4) {
            validate = true
            setcontactError(t('contactNoisrequired'));
        } else {
            setcontactError('');
        }
        if (password == '') {
            validate = true
            setpasswordError(t('passwordError'));
        } else if (password.length < 8) {
            setpasswordError('password must be at least 8 characters');
        } else {
            setpasswordError('');
        }
        if (imageName == '') {
            validate = true
            setCompanyLogoUploadError('Please Upload Company Logo');
        } else {
            setCompanyLogoUploadError('');
        }

        if (selectedOption == "qr") {
            if (qrFileName == '') {
                validate = true
                setQrUploadError('Please Upload Qr Code For Company Website');
            } else {
                setQrUploadError('');
            }
        }
        else if (selectedOption == "website") {
            if (websiteLink == '') {
                validate = true
                setWebsiteLinkError('Company Website Link is required');
            } else {
                setWebsiteLinkError('');
            }
        }
        if (validate == true) {
            // Find the topmost mandatory field that is not filled
            let topmostFieldRef;
            if (!field1Ref.current.value) {
                topmostFieldRef = field1Ref;
            }
            else if (!licenseNo) {
                topmostFieldRef = field1Ref;
            }
            else if (imageName == "") {
                topmostFieldRef = field5Ref;
            }
            else if (selectedOption == "qr") {
                if (qrFileName == "")
                    topmostFieldRef = field7Ref
            }
            else if (selectedOption == "website") {
                if (websiteLink == "")
                    topmostFieldRef = field7Ref
            }

            else if (!field2Ref.current.value) {
                topmostFieldRef = field2Ref;
            }
            else if (!field3Ref.current.value || !(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(field3Ref.current.value))) {
                topmostFieldRef = field3Ref;
            }
            else if (!field6Ref.current.value) {
                topmostFieldRef = field6Ref;
            }

            // Add more conditions for other mandatory fields as needed

            // Scroll to the topmost mandatory field
            if (topmostFieldRef && topmostFieldRef.current) {
                scroller.scrollTo(topmostFieldRef.current.id, {
                    smooth: true,
                    offset: -100, // Adjust as needed
                });
            }
            else if (imageName == "") {
                scroller.scrollTo(topmostFieldRef.current.id, {
                    smooth: true,
                    offset: -100, // Adjust as needed
                });
            }
            else if (contact.length <= 4) {
                scroller.scrollTo(field4Ref.current.id, {
                    smooth: true,
                    offset: -100, // Adjust as needed
                });
            }

            return;
        }

        if (validate == false) {
            setLoading(true)

            if (getAgentOffData?.agentType == 'create' && companyMode?.mode == 0) {

                const params = {

                    CompanyName: companyName == "" ? null : companyName,
                    CompanyContactNo: companyContactNo === "" ? null : (companyContactNo.startsWith("+") ? companyContactNo : `+${companyContactNo}`),
                    CompanyDesignation: designation == "" ? null : designation,
                    CompanyLicNo: licenseNo == "" ? null : licenseNo,
                    CompanyRegNo: companyReg == "" ? null : companyReg,
                    LicenseAuthority: licenseAuthority == "" ? null : licenseAuthority,
                    FaxNo: faxNo == "" ? null : faxNo,
                    LicenseExpiryDate: expiryDate == "" ? null : validateAndFormatDate(expiryDate),
                    CompanyCountryId: country == "" ? null : country,
                    CompanyStateId: stateName == "" ? null : stateName,
                    CompanyCityId: cityId == "" ? null : cityId,
                    CompanyZipcode: zipCode == "" ? null : zipCode,
                    CompanyAddress: companyAddress == "" ? null : companyAddress,
                    FirstName: firstName == "" ? null : firstName,
                    LastName: lastName == "" ? null : lastName,
                    Email: email == "" ? null : email,
                    Phone: contact === "" ? null : (contact.startsWith("+") ? contact : `+${contact}`),
                    Age: age == "" ? null : age,
                    Dob: date == "" ? null : date,
                    Idnumber: idNumber == "" ? null : idNumber,
                    Password: password == "" ? null : password,
                    PassportExpiryDate: passportExpireDate == "" ? null : passportExpireDate,
                    Nationality: nationality == "" ? null : nationality,
                    CountryOfResidence: countryOfResidence == "" ? null : countryOfResidence,
                    MaritalStatus: maritalStatus == "" ? null : maritalStatus,
                    Address: address == "" ? null : address,
                    CompanyLogoBase64: imageByteCode == "" ? null : imageByteCode,
                    CompanyLogoFileNameWithExtension: imageName == "" ? null : imageName,
                    CompanyDescription: discription == "" ? null : discription,
                    QRCodeImageBase64: selectedOption == "qr" ? (qrByteCode == "" ? null : qrByteCode) : null,
                    QRCodeImageFileNameWithExtension: selectedOption == "qr" ? qrFileName : null,
                    QRCodeUrl: selectedOption == "website" ? websiteLink : null,
                    UserImageFileNameWithExtension: (profilePictureFileName == null || profilePictureFileName == "") ? null : profilePictureFileName,
                    UserImageBase64: profilePictureByteCode == "" ? null : profilePictureByteCode,
                    IsQRcode: selectedOption == "qr" ? true : false
                }
                dispatch(createAgentApi(token, params))
                    .then((response) => {
                        dispatch(companyModeApi(1))
                        const params = {
                            pagenumber: null
                        }
                        dispatch(agentApi(token, params))
                            .then((response) => {
                                toast.success("Record Saved Successfully", {
                                    position: 'top-right',
                                    autoClose: 1000, // Adjust the duration as needed
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                                setTimeout(() => {

                                    setLoading(false)
                                    navigate('/AgentList')

                                }, 1000);


                            })
                            .catch((error) => {

                            });

                    })
                    .catch((error) => {

                        setLoading(false)
                        toast.error(error, {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });

                    });
            } else {
                const params = {
                    ComanyId: getAgentOffData?.agentType == 'create' ? createAgentData?.CompanyId : getAgentDetails?.ComanyId,
                    CompanyName: companyName == "" ? null : companyName,
                    CompanyContactNo: companyContactNo === "" ? null : (companyContactNo.startsWith("+") ? companyContactNo : `+${companyContactNo}`),
                    CompanyDesignation: designation == "" ? null : designation,
                    CompanyLicNo: licenseNo == "" ? null : licenseNo,
                    CompanyRegNo: companyReg == "" ? null : companyReg,
                    LicenseAuthority: licenseAuthority == "" ? null : licenseAuthority,
                    FaxNo: faxNo == "" ? null : faxNo,
                    LicenseExpiryDate: expiryDate == "" ? null : validateAndFormatDate(expiryDate),
                    CompanyCountryId: country == "" ? null : country,
                    CompanyStateId: stateName == "" ? null : stateName,
                    CompanyCityId: cityId == "" ? null : cityId,
                    CompanyZipcode: zipCode == "" ? null : zipCode,
                    CompanyAddress: companyAddress == "" ? null : companyAddress,
                    FirstName: firstName == "" ? null : firstName,
                    LastName: lastName == "" ? null : lastName,
                    Phone: contact === "" ? null : (contact.startsWith("+") ? contact : `+${contact}`),
                    Age: age == "" ? null : age,
                    Dob: date == "" ? null : validateAndFormatDate(date),
                    Idnumber: idNumber == "" ? null : idNumber,
                    PassportExpiryDate: passportExpireDate == "" ? null : validateAndFormatDate(passportExpireDate),
                    Nationality: nationality == "" ? null : nationality,
                    CountryOfResidence: countryOfResidence == "" ? null : countryOfResidence,
                    MaritalStatus: maritalStatus == "" ? null : maritalStatus,
                    Address: address == "" ? null : address,
                    CompanyLogoBase64: imageByteCode == "" ? null : imageByteCode,
                    CompanyLogoFileNameWithExtension: imageName == "" ? null : imageName,
                    CompanyDescription: discription == "" ? null : discription,
                    QRCodeImageBase64: selectedOption == "qr" ? (qrByteCode == "" ? null : qrByteCode) : null,
                    QRCodeImageFileNameWithExtension: selectedOption == "qr" ? qrFileName : null,
                    QRCodeUrl: selectedOption == "website" ? websiteLink : null,
                    UserImageFileNameWithExtension: (profilePictureFileName == null || profilePictureFileName == "") ? null : profilePictureFileName,
                    UserImageBase64: profilePictureByteCode == "" ? null : profilePictureByteCode,
                    IsQRcode: selectedOption == "qr" ? true : false
                }

                dispatch(editAgentApi(token, params))
                    .then((response) => {
                        const params = {
                            pagenumber: null
                        }
                        dispatch(agentApi(token, params))
                            .then((response) => {
                                toast.success("Record Saved Successfully", {
                                    position: 'top-right',
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                                setTimeout(() => {
                                    setLoading(false)
                                    navigate('/AgentList')

                                }, 1000);

                            })

                    })
                    .catch((error) => {

                    });
            }


        }

    };

    //DELETE AGENT CREDIT API CALL
    const deleteAgentCreditApiCall = () => {
        setLoading(true)

        dispatch(deleteAgentCreditListApi(token, creditSelectedId))
            .then(() => {
                const params = {
                    pagenumber: 1,
                    agentId: getAgentOffData?.agentType == 'create' ? createAgentData?.AgentId : getAgentDetails?.UserId
                }
                dispatch(agentCreditListApi(token, params))
                    .then(() => {
                        setLoading(false)

                        setAgentCreditListPage(1)
                        setcreditPopOpen(false)

                    })
                    .catch(error => {
                        setLoading(false)

                    })
            })
            .catch(error => {

            })
    }



    const openCloseCompanyAccordingList = () => {
        setCompanyAccordingList(!companyAccordingList)
    }

    const openClosePrimaryAccordingList = () => {
        setPrimaryAccordingList(!primaryAccordingList)
    }

    const handleOpen = (agentId) => {
        setSelectedDocumentId(agentId)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const handleCreditClose = () => {
        setcreditPopOpen(false);
    };




    const handleChanges = (event, newValue) => {

        setValue(newValue);
    };


    //ONCLICK DELETE DOCUMENT API CALL
    const onClickDeleteDoc = async () => {

        try {
            const response = await axios.get(`${config.baseURL}/User/DeleteFile?id=${selectedDocumentId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status == 200) {
                setAgentUploadDocumentsListPage(1)
                if (response.data.Status == 'OK') {

                    const params = {
                        pagenumber: 1,
                        UserId: getAgentOffData?.agentType == 'create' ? createAgentData?.AgentId : getAgentDetails?.UserId
                    }
                    dispatch(agentUploadDocumentListApi(token, params))

                        .then((response) => {
                            setOpen(false)
                        })
                        .catch((error) => {

                        });
                } else if (response.data.Status == 'ERROR') {

                }

            } else {

            }
        } catch (error) {

        }
    };
    const handleExpireDate = (e) => {
        setInputExpiredate('date');
        e.target.blur()
    };

    //FILE DOWNLOAD FUNCTION
    const handleDownload = (item) => {
        // Specify the URL of the file you want to download
        const fileUrl = item?.FileUrl;

        // Specify the desired filename
        const fileName = item?.Name;

        // Construct the full URL with the filename
        const urlWithFileName = `${config.baseURL}${fileUrl}?filename=${fileName}`;

        // Open a new tab and set its location to the constructed URL
        window.open(urlWithFileName, '_blank');

    };

    //COMPANY LOGO UPLOAD
    const handleImageChange = (event) => {
        const file = event.target.files[0];

        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];

        if (!allowedTypes.includes(file?.type)) {
            alert('Please upload a JPG, JPEG or PNG File');
            event.target.value = null;
            event.target.dispatchEvent(new Event('input'));
            return; // Exit the function if file type is not allowed
        }

        const reader = new FileReader();

        reader.onloadend = () => {
            const image = new Image();
            image.src = reader.result;
            image.onload = () => {
                const { width, height } = image;
                if (width !== 500 || height !== 500) {
                    alert('Image must be 500px width and 500px height.');
                    event.target.value = null;
                    event.target.dispatchEvent(new Event('input'));
                    return; // Exit the function if image dimensions are not correct
                }
                const byteCode = reader.result;
                setImageByteCode(byteCode);
                setImageName(file.name);
            };
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };



    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleQRChange = (event) => {
        const file = event.target.files[0];

        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];

        if (!allowedTypes.includes(file?.type)) {
            alert('Please upload a JPG, JPEG or PNG File');
            event.target.value = null;
            event.target.dispatchEvent(new Event('input'));
            return; // Exit the function if file type is not allowed
        }

        const reader = new FileReader();

        reader.onloadend = () => {
            const image = new Image();
            image.src = reader.result;
            image.onload = () => {
                // const { width, height } = image;
                // if (width !== 500 || height !== 500) {
                //     alert('Image must be 500px width and 500px height.');
                //     event.target.value = null;
                //     event.target.dispatchEvent(new Event('input'));
                //     return; // Exit the function if image dimensions are not correct
                // }
                const byteCode = reader.result;
                setQrByteCode(byteCode);
                setQrFileName(file.name);
            };
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleProfilePicUploadChange = (event) => {
        const file = event.target.files[0];

        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];

        if (!allowedTypes.includes(file?.type)) {
            alert('Please upload a JPG, JPEG or PNG File');
            event.target.value = null;
            event.target.dispatchEvent(new Event('input'));
            return; // Exit the function if file type is not allowed
        }

        const reader = new FileReader();

        reader.onloadend = () => {
            const image = new Image();
            image.src = reader.result;
            image.onload = () => {
                // const { width, height } = image;
                // if (width !== 500 || height !== 500) {
                //     alert('Image must be 500px width and 500px height.');
                //     event.target.value = null;
                //     event.target.dispatchEvent(new Event('input'));
                //     return; // Exit the function if image dimensions are not correct
                // }
                const byteCode = reader.result;
                setProfilePictureByteCode(byteCode);
                setProfilePictureFileName(file.name);
            };
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };



    return (
        <div className="App">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}

            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box_popup'
            >

                <DialogContent class="dialog_box_block">
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
                        <h6>Delete</h6>
                        <Button onClick={handleClose}><img src={closeModal} /></Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">
                        Are you sure you want to delete this document ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions class="dialog_box_button">
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => onClickDeleteDoc()}>Delete</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={creditPopOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCreditClose}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box_popup'
            >

                <DialogContent class="dialog_box_block">
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
                        <h6>Delete</h6>
                        <Button onClick={handleCreditClose}><img src={closeModal} /></Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">
                        Are you sure you want to delete this credit.
                    </DialogContentText>
                </DialogContent>
                <DialogActions class="dialog_box_button">
                    <Button onClick={handleCreditClose}>Cancel</Button>
                    <Button onClick={() => deleteAgentCreditApiCall()}>Delete</Button>
                </DialogActions>
            </Dialog>

            <div class="overlay"></div>

            <Topbar />

            <Sidebar message={'AgentList'} />

            <section class="content">
                <div id="fieldDefaultTop" ref={fieldDefaultRefTop} class="block-header">
                    <h2>{t('agentDetails')}</h2>

                </div>

                <div class="row clearfix">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="tab_block">
                            <Tabs
                                value={value}

                                onChange={handleChanges}
                                className='tab-nav-right'
                                aria-label="wrapped label tabs example"
                            >
                                <Tab className='tabs_title' value="one" label={t('agentDetails')} wrapped />
                                <Tab className='tabs_title' value="two" label={t('documents')} disabled={companyMode?.mode == 0 ? true : false} />
                                <Tab className='tabs_title' value="three" label={t('noCredits')} disabled={companyMode?.mode == 0 ? true : false} />
                            </Tabs>
                        </div>

                        <div class="tab_content_block">

                            {
                                value == 'one' ?
                                    <>
                                        <div class="panel-group full-body" id="accordion_3" role="tablist" aria-multiselectable="true">
                                            <div class="panel">
                                                <div class="panel-heading" role="tab" id="headingOne_3">
                                                    <h4 class="panel-title">
                                                        <a href="javascript:void(0)" role="button" onClick={() => openCloseCompanyAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_3">
                                                            <span>{t('companyDetails')}</span>
                                                            <i className={companyAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                        </a>
                                                    </h4>
                                                </div>
                                                {companyAccordingList ?
                                                    <div id="collapseOne_3" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_3">
                                                        <div class="panel-body">
                                                            <div class="card">
                                                                <div className="form_block">
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" title="Company Name" class="form-control" id="field1" value={companyName} ref={field1Ref} onChange={(e) => { setCompanyName(e.target.value); validateCompanyName(e.target.value); }} placeholder={t('companyName') + '*'} />
                                                                                    {
                                                                                        companyNameError && (
                                                                                            <span class="validation_notes">{companyNameError}</span>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" title="Designation" class="form-control" placeholder={t('designation')} value={designation} onChange={(e) => { setDesignation(e.target.value) }} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" title="Broker License No." class="form-control" maxLength={25} placeholder={t('licenseNo') + '*'} value={licenseNo} onChange={(e) => { setLicenseNo(e.target.value); validateLicenseNo(e.target.value) }} />
                                                                                    {
                                                                                        licenseNoError && (
                                                                                            <span class="validation_notes">{licenseNoError}</span>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" title="Commercial Reg. Number" class="form-control" maxLength={25} placeholder={t('companyRegNumber')} value={companyReg} onChange={(e) => { setCompanyReg(e.target.value); }} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" title="License Authority" class="form-control" placeholder={t('licenseAuthority')} value={licenseAuthority} onChange={(e) => { setLicenseAuthority(e.target.value); }} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type={inputExpiredate}
                                                                                        title="Expiry Date"
                                                                                        onFocus={(e) => handleExpireDate(e)} class="form-control" placeholder={t('expiryDate')} value={expiryDate}
                                                                                        onKeyDown={(e) => e.preventDefault()} // Prevent typing
                                                                                        onKeyPress={(e) => e.preventDefault()} // Prevent typing
                                                                                        onKeyUp={(e) => e.preventDefault()} // Prevent typing
                                                                                        onPaste={(e) => e.preventDefault()} // Prevent pasting
                                                                                        onChange={(e) => { setExpiryDate(e.target.value); }} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6">
                                                                            <div>
                                                                                <FormControl className='form-group' sx={{}}>
                                                                                    <Select
                                                                                        className='form-control border_temp'
                                                                                        sx={{ borderColor: 'transparent' }}

                                                                                        displayEmpty
                                                                                        value={country}
                                                                                        title={t('country')}
                                                                                        onChange={handleCountryChange}
                                                                                        input={<OutlinedInput />}

                                                                                        MenuProps={{
                                                                                            PaperProps: {
                                                                                                style: {
                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                    width: 250,
                                                                                                },
                                                                                            },
                                                                                            disableScrollLock: true,
                                                                                        }}
                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                    >
                                                                                        <MenuItem className='drop_menus' value="">
                                                                                            <em className='select_text'>{t('country')}</em>
                                                                                        </MenuItem>
                                                                                        {countryData?.map((item) => (
                                                                                            <MenuItem
                                                                                                className='drop_menus'
                                                                                                key={item?.Id}
                                                                                                value={item?.Id}
                                                                                                style={getStyles(item?.Name, city, theme)}
                                                                                            >
                                                                                                {item?.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>

                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div>
                                                                                <FormControl className='form-group' sx={{}}>
                                                                                    <Select
                                                                                        className='form-control border_temp'
                                                                                        sx={{ borderColor: 'transparent' }}
                                                                                        title={t('state')}

                                                                                        displayEmpty
                                                                                        value={stateName}
                                                                                        onChange={handleStateChange}
                                                                                        input={<OutlinedInput />}

                                                                                        MenuProps={{
                                                                                            PaperProps: {
                                                                                                style: {
                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                    width: 250,
                                                                                                },
                                                                                            },
                                                                                            disableScrollLock: true,
                                                                                        }}
                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                    >
                                                                                        <MenuItem className='drop_menus' value="">
                                                                                            <em className='select_text'>{t('state')}</em>
                                                                                        </MenuItem>
                                                                                        {stateData?.map((item) => (
                                                                                            <MenuItem
                                                                                                className='drop_menus'
                                                                                                key={item?.Id}
                                                                                                value={item?.Id}
                                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                                            >
                                                                                                {item?.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>


                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6">
                                                                            <div>
                                                                                <FormControl className='form-group' sx={{}}>
                                                                                    <Select
                                                                                        className='form-control border_temp'
                                                                                        sx={{ borderColor: 'transparent' }}
                                                                                        title={t('city')}
                                                                                        displayEmpty
                                                                                        value={cityId}
                                                                                        onChange={handleCityChange}
                                                                                        input={<OutlinedInput />}

                                                                                        MenuProps={{
                                                                                            PaperProps: {
                                                                                                style: {
                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                    width: 250,
                                                                                                },
                                                                                            },
                                                                                            disableScrollLock: true,
                                                                                        }}
                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                    >
                                                                                        <MenuItem className='drop_menus' value="">
                                                                                            <em className='select_text'>{t('city')}</em>
                                                                                        </MenuItem>
                                                                                        {cityData?.map((item) => (
                                                                                            <MenuItem
                                                                                                className='drop_menus'
                                                                                                key={item?.Id}
                                                                                                value={item?.Id}
                                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                                            >
                                                                                                {item?.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>

                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" title="Zip Code" class="form-control" placeholder={t('zipCode')} maxLength={15} value={zipCode} onKeyPress={handleKeyPress} onChange={(e) => setzipCode(e.target.value)} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">

                                                                        <div class="col-sm-6">

                                                                            <div class="form-group phone-input">
                                                                                <Tooltip title={'Company Contact No.'} arrow className="tooltip_notes">
                                                                                    <PhoneInput
                                                                                        placeholder='Company Contact No.'
                                                                                        country={"bh"}
                                                                                        enableSearch={true}
                                                                                        value={companyContactNo}
                                                                                        onChange={(e) => setCompanyContactNo(e)}
                                                                                    />

                                                                                    {/* <input type="text" title="Company Contact No." class="form-control" id="field4" placeholder="Company Contact No." maxLength={15} value={companyContactNo} onChange={(e) => { setCompanyContactNo(e.target.value) }} onKeyPress={handleKeyPressPhno} /> */}
                                                                                    {
                                                                                        companyContactNoError && (
                                                                                            <span class="validation_notes">{companyContactNoError}</span>
                                                                                        )
                                                                                    }
                                                                                </Tooltip>
                                                                            </div>

                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" title={t('faxNo')} class="form-control" placeholder={t('faxNo')} value={faxNo} onChange={(e) => { setFaxNo(e.target.value); }} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-12">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <div class="custom-file" >
                                                                                        <input type="file" title="Select Your Company Image" class="custom-file-input" id="customFile" ref={field5Ref} onChange={handleImageChange} />

                                                                                        <label class="custom-file-label" for="customFile">{imageName ? imageName : "Select Your Company Image"}</label>
                                                                                    </div>
                                                                                    <p class="logo_note">Note : Logo Must be 500px X 500px</p>
                                                                                    {
                                                                                        companyLogoUploadError && (
                                                                                            <span class="validation_notes">{companyLogoUploadError}</span>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>                               <div class="row clearfix">
                                                                        <div class="col-sm-12">
                                                                            <div class="form-group">
                                                                                <label class="custom-file-label">Company QR or Website Link</label>
                                                                                <div class="radio">
                                                                                    <input type="radio" id="qrRadio" name="qrOrWebsite" value="qr" checked={selectedOption === 'qr'} onChange={handleRadioChange} />
                                                                                    <label class="custom-file-label" for="qrRadio">QR Code</label>
                                                                                </div>
                                                                                <div class="radio">
                                                                                    <input type="radio" id="websiteRadio" name="qrOrWebsite" value="website" checked={selectedOption === 'website'} onChange={handleRadioChange} />
                                                                                    <label class="custom-file-label" for="websiteRadio">Website Link</label>
                                                                                </div>


                                                                                <div class="form-line" id="field7" ref={field7Ref}>
                                                                                    {selectedOption === 'qr' ? (
                                                                                        <div class="custom-file" >
                                                                                            <input type="file" title="Select Company QR Code Image" class="custom-file-input" id="customQRFile" onChange={handleQRChange} />
                                                                                            <label class="custom-file-label" for="customQRFile">{qrFileName ? qrFileName : "Select QR Code Image"}</label>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <input type="url" title="Website URL" class="form-control" id="websiteLink" placeholder="Website Link*" value={websiteLink} onChange={(e) => { setWebsiteLink(e.target.value); validateWebsiteLink(e.target.value) }} />
                                                                                    )}
                                                                                </div>

                                                                                <p class="logo_note">Note: If you select Website, provide a valid URL (e.g., https://www.example.com)</p>

                                                                                {selectedOption == "qr" &&
                                                                                    (qrUploadError && (
                                                                                        <span class="validation_notes">
                                                                                            {qrUploadError}
                                                                                        </span>)
                                                                                    )
                                                                                }
                                                                                {
                                                                                    selectedOption == "website" &&
                                                                                    (websiteLinkError && (
                                                                                        <span class="validation_notes">
                                                                                            {websiteLinkError}
                                                                                        </span>)
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-12">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <textarea title={t('Company Description')} rows="4" class="form-control no-resize" placeholder={t('Company Description')} value={discription} onChange={(e) => setDiscription(e.target.value)}></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-12">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <textarea rows="4" title={t('companyAddress')} class="form-control no-resize" placeholder={t('companyAddress')} value={companyAddress} onChange={(e) => { setCompanyAddress(e.target.value); }}></textarea>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                        <div class="panel-group full-body" id="accordion_4" role="tablist" aria-multiselectable="true">
                                            <div class="panel">
                                                <div class="panel-heading" role="tab" id="headingOne_4">
                                                    <h4 class="panel-title">
                                                        <a href="javascript:void(0)" role="button" onClick={() => openClosePrimaryAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_4">
                                                            <span>{t('primaryDetails')}</span>
                                                            <i className={primaryAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                        </a>
                                                    </h4>
                                                </div>
                                                {primaryAccordingList ?
                                                    <div id="collapseOne_4" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_4">
                                                        <div class="panel-body">
                                                            <div class="card">
                                                                <div class="form_block">
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" title={t('firstName')} class="form-control" id="field2" placeholder={t('firstName') + '*'} ref={field2Ref} value={firstName} onChange={(e) => { setFirstName(e.target.value); validateFirstName(e.target.value) }} />
                                                                                    {
                                                                                        firstNameError && (
                                                                                            <span class="validation_notes">{firstNameError}</span>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" title={t('lastName')} class="form-control" placeholder={t('lastName')} value={lastName} onChange={(e) => { setLastName(e.target.value) }} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" title={t('email')} class="form-control" id="field3" disabled={companyMode?.mode == 0 ? false : true} placeholder={t('email') + '*'} ref={field3Ref} value={email} onChange={(e) => { setEmail(e.target.value); validateEmail(e.target.value) }} />
                                                                                    {
                                                                                        emailError && (
                                                                                            <span class="validation_notes">{emailError}</span>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group phone-input" ref={field4Ref} id="field4Ref">
                                                                                <Tooltip title={'Contact No.'} arrow className="tooltip_notes">

                                                                                    <PhoneInput
                                                                                        placeholder='Contact No.*'
                                                                                        country={"bh"}
                                                                                        enableSearch={true}
                                                                                        value={contact}
                                                                                        onChange={(e) => { setContact(e); validateContact(e) }}
                                                                                    />
                                                                                    {/* <input type="text" title={t('contact')} class="form-control" id="field4" placeholder={t('contact') + '*'} ref={field4Ref} maxLength={15} value={contact} onChange={(e) => { setContact(e.target.value); validateContact(e.target.value) }} onKeyPress={handleKeyPressPhno} /> */}
                                                                                    {
                                                                                        contactError && (
                                                                                            <span class="validation_notes">{contactError}</span>
                                                                                        )
                                                                                    }
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" title={t('age')} class="form-control" placeholder={t('age')} value={age} maxLength={3} onKeyPress={handleKeyPress} onChange={(e) => { setAge(e.target.value); }} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input class="form-control"
                                                                                        title={t('dateofBirth')}
                                                                                        max={today}
                                                                                        type={inputTypeBirthdate}
                                                                                        onKeyDown={(e) => e.preventDefault()} // Prevent typing
                                                                                        onKeyPress={(e) => e.preventDefault()} // Prevent typing
                                                                                        onKeyUp={(e) => e.preventDefault()} // Prevent typing
                                                                                        onPaste={(e) => e.preventDefault()} // Prevent pasting
                                                                                        onInput={(e) => e.preventDefault()}
                                                                                        onFocus={(e) => handleFocusDate(e)}
                                                                                        placeholder={t('dateofBirth')} value={date} onChange={(e) => { setDate(e.target.value); }} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" class="form-control" title={t('idNumber')} placeholder={t('idNumber')} value={idNumber} onChange={(e) => { setIdNumber(e.target.value); }} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            getAgentOffData?.agentType == 'create' && companyMode?.mode == 0 && (
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-group icon_field">
                                                                                        <div class="form-line">
                                                                                            <input ref={field6Ref} id="field6Ref" type={passwordShow ? 'text' : 'password'} title={t('password')} class="form-control" placeholder={t('password') + '*'} value={password} onChange={(e) => { setPassword(e.target.value); validatePassword(e.target.value) }} />
                                                                                            {
                                                                                                passwordError && (
                                                                                                    <span class="validation_notes">{passwordError}</span>
                                                                                                )
                                                                                            }
                                                                                            <span class="eye_icon">
                                                                                                <a onClick={() => setpasswordShow(!passwordShow)}>
                                                                                                    {
                                                                                                        passwordShow ?
                                                                                                            <span class="material-icons">visibility</span>
                                                                                                            :
                                                                                                            <span class="material-icons">visibility_off</span>
                                                                                                    }
                                                                                                </a>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }

                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input
                                                                                        type={inputTypeExpiredate}
                                                                                        title={t('passportExpiryDate')}
                                                                                        onFocus={(e) => handleFocusExpireDate(e)}
                                                                                        class="form-control" placeholder={t('passportExpiryDate')} value={passportExpireDate}
                                                                                        onKeyDown={(e) => e.preventDefault()} // Prevent typing
                                                                                        onKeyPress={(e) => e.preventDefault()} // Prevent typing
                                                                                        onKeyUp={(e) => e.preventDefault()} // Prevent typing
                                                                                        onPaste={(e) => e.preventDefault()} // Prevent pasting
                                                                                        onChange={(e) => { setPassportExpireDate(e.target.value); }} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div>
                                                                                <FormControl className='form-group' sx={{}}>
                                                                                    <Select
                                                                                        className='form-control border_temp'
                                                                                        sx={{ borderColor: 'transparent' }}
                                                                                        title={t('nationality')}
                                                                                        displayEmpty
                                                                                        value={nationality}
                                                                                        onChange={(e) => { setNationality(e.target.value); }}
                                                                                        input={<OutlinedInput />}
                                                                                        MenuProps={{
                                                                                            PaperProps: {
                                                                                                style: {
                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                    width: 250,
                                                                                                },
                                                                                            },
                                                                                            disableScrollLock: true,
                                                                                        }}
                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                    >
                                                                                        <MenuItem className='drop_menus' value="">
                                                                                            <em className='select_text'>{t('nationality')}</em>
                                                                                        </MenuItem>
                                                                                        {countryData?.map((item) => (
                                                                                            <MenuItem
                                                                                                className='drop_menus'
                                                                                                key={item?.Id}
                                                                                                value={item?.Id}
                                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                                            >
                                                                                                {item?.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>

                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6">
                                                                            <div>
                                                                                <FormControl className='form-group' sx={{}}>
                                                                                    <Select
                                                                                        className='form-control border_temp'
                                                                                        sx={{ borderColor: 'transparent' }}
                                                                                        title={t('countryofResidence')}
                                                                                        displayEmpty
                                                                                        value={countryOfResidence}
                                                                                        onChange={(e) => { setCountryOfResidence(e.target.value); }}
                                                                                        input={<OutlinedInput />}

                                                                                        MenuProps={{
                                                                                            PaperProps: {
                                                                                                style: {
                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                    width: 250,
                                                                                                },
                                                                                            },
                                                                                            disableScrollLock: true,
                                                                                        }}
                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                    >
                                                                                        <MenuItem className='drop_menus' value="">
                                                                                            <em className='select_text'>{t('countryofResidence')}</em>
                                                                                        </MenuItem>
                                                                                        {countryData?.map((item) => (
                                                                                            <MenuItem
                                                                                                className='drop_menus'
                                                                                                key={item?.Id}
                                                                                                value={item?.Id}
                                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                                            >
                                                                                                {item?.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>

                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div>
                                                                                <FormControl className='form-group' sx={{}}>
                                                                                    <Select
                                                                                        className='form-control border_temp'
                                                                                        sx={{ borderColor: 'transparent' }}
                                                                                        title={t('maritalStatus')}
                                                                                        displayEmpty
                                                                                        value={maritalStatus}
                                                                                        onChange={(e) => { setMaritalStatus(e.target.value); }}
                                                                                        input={<OutlinedInput />}

                                                                                        MenuProps={{
                                                                                            PaperProps: {
                                                                                                style: {
                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                    width: 250,
                                                                                                },
                                                                                            },
                                                                                            disableScrollLock: true,
                                                                                        }}
                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                    >
                                                                                        <MenuItem className='drop_menus' value="">
                                                                                            <em className='select_text'>{t('maritalStatus')}</em>
                                                                                        </MenuItem>
                                                                                        {maritialStatusData?.map((item) => (
                                                                                            <MenuItem
                                                                                                className='drop_menus'
                                                                                                key={item?.Id}
                                                                                                value={item?.Id}
                                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                                            >
                                                                                                {item?.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>

                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-12">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <textarea title={t('address')} rows="4" class="form-control no-resize" placeholder={t('address')} value={address} onChange={(e) => { setAddress(e.target.value); }} ></textarea>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-12">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <div class="custom-file" >
                                                                                        <input type="file" title="Upload Profile Picture" id="customProfilePicFile" class="custom-file-input" onChange={handleProfilePicUploadChange} />

                                                                                        <label class="custom-file-label" for="customProfilePicFile">{profilePictureFileName ? profilePictureFileName : "Upload Profile Picture"}</label>
                                                                                    </div>
                                                                                    {/* <p class="logo_note">Note : Logo Must be 500px X 500px</p> */}

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="button_block">
                                                                        <a href="#" class="links" onClick={() => agentDetailApi()}>{t('save')}</a>
                                                                        <a href="#" class="links" onClick={() => agentDetailNextApi()}>{t('next')}</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                    </> : null
                            }
                            {
                                value == 'two' ?
                                    <>
                                        <div class="main_class">
                                            <div class="file-drop-area">
                                                <img src={folder} />
                                                <span class="file-message">{t('dragandDrop')}</span>
                                                <span class="choose-file-button">{t('uploadDocument')}</span>
                                                <input class="file-input" type="file" onChange={handleFileChange} />
                                                {
                                                    documentError && (
                                                        <span class="validation_notes">{documentError}</span>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div class="main_class">
                                            <div class="table_block">
                                                <div class="table-responsive">
                                                    {
                                                        agentUploadDocumentsList?.Data?.length != 0 ?
                                                            <table class="table table-bordered table-striped table-hover dataTable custom">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Sr. No.</th>
                                                                        <th>{t('name')}</th>
                                                                        <th>{t('contact')}</th>
                                                                        <th>{t('fileName')}</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        agentUploadDocumentsList?.Data?.map((item) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>
                                                                                        <p>{item?.RowNum}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p >{item?.Name}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p>{item?.Phone}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <a onClick={() => handleDownload(item)}><p class='blue_text'>{item?.FileName}</p></a>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail">
                                                                                            <a href="#" class="link red" onClick={() => handleOpen(item.Id)}><img src={deleteIcon} /></a>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }


                                                                </tbody>
                                                            </table>
                                                            :
                                                            <h1 class="text-center">No Data Found</h1>
                                                    }

                                                </div>
                                                <div class="text-right">
                                                    <Pagination className="pagination" count={agentUploadDocumentsList?.TotalPages} page={agentUploadDocumentsListPage} onChange={pageHandleChange} />
                                                </div>
                                            </div>
                                            <div class="button_block">
                                                <a href="#" class="links" onClick={() => setValue('one')}>{t('back')}</a>

                                                <a href="#" class="links" onClick={() => onClickDocumentNext()}>{t('onNext')}</a>
                                            </div>
                                        </div>
                                    </> : null
                            }
                            {
                                value == 'three' ?
                                    <>
                                        <div class="panel-group full-body" id="accordion_4" role="tablist" aria-multiselectable="true">
                                            <div class="panel">
                                                <div class="panel-heading" role="tab" id="headingOne_4">
                                                    <h4 class="panel-title">
                                                        <a href="javascript:void(0)" role="button" onClick={() => openClosePrimaryAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_4">
                                                            <span>{t('addNoofCredits')}</span>
                                                            <i className={primaryAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                        </a>
                                                    </h4>
                                                </div>
                                                {primaryAccordingList ?
                                                    <div id="collapseOne_4" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_4">
                                                        <div class="panel-body">
                                                            <div class="card">
                                                                <div class="form_block">
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-12">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" class="form-control" placeholder={t('totalNoofCredits')} maxLength={maxLength} value={credit} onChange={(e) => setCredit(e.target.value)} onKeyPress={handleKeyPress} />
                                                                                    {
                                                                                        creditError && (
                                                                                            <span class="validation_notes">{creditError}</span>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <textarea rows="4" class="form-control no-resize" placeholder={t('description')} value={description} onChange={(e) => { setDescription(e.target.value); validateDec(e.target.value) }}></textarea>
                                                                                    {
                                                                                        descriptionError && (
                                                                                            <span class="validation_notes">{descriptionError}</span>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="button_block">

                                                            <a href="#" class="links" onClick={() => onClickCredit()}>{t('save')}</a>
                                                        </div>
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                        <div class="main_class">
                                            <div class="table_block">
                                                <div class="table-responsive">
                                                    {
                                                        agentCreditList?.Data?.length != 0 ?
                                                            <table class="table table-bordered table-striped table-hover dataTable custom">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{t('id')}</th>
                                                                        <th>{t('date')}</th>
                                                                        <th>{t('description')}</th>
                                                                        <th>{t('noCredits')}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tfoot>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th>{t('totalNoofCredits')}</th>
                                                                        <th >{agentCreditList?.TotalCredits}</th>
                                                                    </tr>
                                                                </tfoot>
                                                                <tbody>
                                                                    {
                                                                        agentCreditList?.Data?.map((item) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>
                                                                                        <p>{item?.Id}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p>{item?.Date}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p>{item?.Description}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p >{item?.Credits}</p>
                                                                                    </td>

                                                                                </tr>

                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table> :
                                                            <h1 class="text-center">No Data Found</h1>
                                                    }

                                                </div>
                                                <div class="text-right">
                                                    <Pagination className="pagination" count={agentCreditList?.TotalPages} page={agentCreditListPage} onChange={pageHandleChangeAgentCreditList} />
                                                </div>
                                            </div>
                                            <div class="button_block">
                                                <a href="#" class="links" onClick={() => setValue('two')}>{t('back')}</a>
                                                <a href="#" class="links" onClick={() => navigate('/AgentList')}>Done</a>
                                            </div>
                                        </div>
                                    </> : null
                            }
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </div>
    );

}




